@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    /* @apply font-satoshi font-normal text-base text-body bg-whiten relative z-1; */
    background-color: white;
    color: black;
  }
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;400&family=Vollkorn&display=swap');

@layer components {
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .chat-height {
    @apply h- [ calc(100 vh_-_8 .125rem) ] lg: h- [ calc(100 vh_-_5 .625rem) ];
  }

  .inbox-height {
    @apply h- [ calc(100 vh_-_8 .125rem) ] lg: h- [ calc(100 vh_-_5 .625rem) ];
  }
}

/* third-party libraries CSS */

.tableCheckbox:checked ~ div span {
  @apply opacity-100;
}

.tableCheckbox:checked ~ div {
  /*@apply bg-primary border-primary;*/
}

/* loader css start */
.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* height: calc(100vh - 90px); */
  height: 100vh;
}

.loader {
  margin: 0 auto 15px auto;
  width: 50px;
  height: 60px;
  text-align: center;
}

.loader > div {
  background-color: #ff5612;
  height: 100%;
  width: 6px;
  margin-right: 2px;
  display: inline-block;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.loader .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.loader .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.loader .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.loader .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.ant-btn-primary {
  color: white;
  background-color: #ff5612;
  font-weight: bold;
  border-radius: 5px;
}

.ant-btn-primary:hover {
  background-color: #ff5619 !important;
}

.ant-btn-default {
}

.ant-btn-default:hover {
  border: 1px solid #ff5612 !important;
  color: #ff5612 !important;
}

.ant-slider-rail {
}

.ant-slider-track {
}

.ant-btn-primary {
  color: white;
  background-color: #ff5612;
  font-weight: bold;
  border-radius: 5px;
}

.ant-btn-primary:hover {
  background-color: #ff5619 !important;
}

.ant-btn-default {
}

.ant-slider-rail {
}

.ant-slider-track {
}

@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}

/* loader css end */

/* quill editor css */
/* .ql-toolbar.ql-snow {
  display: flex;
  justify-content: center;
} */

/* .ql-toolbar.ql-snow + .ql-container.ql-snow {
  padding-top: 90px;
} */

:where(.css-dev-only-do-not-override-2i2tap).ant-modal .ant-modal-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}

:where(.css-dev-only-do-not-override-2i2tap).ant-modal
  .ant-modal-footer
  .ant-btn
  + .ant-btn:not(.ant-dropdown-trigger) {
  background-color: #ff5612 !important;
}

:where(.css-dev-only-do-not-override-2i2tap).ant-switch.ant-switch-checked:not(
    .ant-switch-disabled
  ) {
  background-color: #e6ffe6 !important;
}

.ql-toolbar.ql-snow {
  background-color: #f6f6f9;
  border: none;
  padding-top: 15px;
  padding-bottom: 15px;
}

.ql-picker-label {
  background-color: white;
  border-radius: 4px;
  padding: 2px;
}

.ql-editor {
  border: none;
}

:where(.css-dev-only-do-not-override-6j9yrn).ant-layout {
  background-color: white !important;
}

.ant-popconfirm-buttons {
  display: none;
}

/* .ql-container {
    padding: 0px 400px 0px 400px;
}

.ql-editor.ql-blank::before {
    top: 102px;
    left: 420px;
} */
.ql-toolbar.ql-snow {
  display: flex !important;
  justify-content: center !important;
}
.ql-container.ql-snow {
  /* border: none; */
  width: 100%;
  padding: 10px;
  min-height: 200px;
  border-radius: 0px 0px 10px 10px;
  margin-bottom: 5px;
}

.ant-form-item {
  margin-bottom: 0;
  padding-bottom: 0;
}

/* @media only screen and (max-width: 1024px) {
    .ql-container {
        padding: 0px 150px 0px 150px;
    }

    .ql-editor.ql-blank::before {
        top: 100px;
        left: 70px;
    }
}

@media only screen and (max-width: 768px) {
    .ql-container {
        padding: 0px 100px 0px 100px;
    }

    .ql-editor.ql-blank::before {
        top: 100px;
        left: 70px;
    }
} */

/* Media query for mobile screens */
/* @media only screen and (max-width: 480px) {
    .ql-container {
        padding: 0px 20px 0px 20px;
    }

    .ql-editor.ql-blank::before {
        top: 100px;
        left: 40px;
    }
} */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Arial, sans-serif;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.2rem;
}

h4 {
  font-size: 1rem;
}

h5 {
  font-size: 0.875rem;
}

h6 {
  font-size: 0.75rem;
}
.ql-editor {
  padding: 0;
}

/* Tab text color change  */

.ant-tabs .ant-tabs-tab {
  color: #c3c3c3;
}

.ant-form-item .ant-form-item-label > label::after {
  display: none;
}

.custom-modal .ant-modal-content {
  padding: 0;
}

/* 
Ck Editor  */

.ck-editor__editable.ck-rounded-corners {
  min-height: 200px;
}

.ck-file-dialog-button {
  display: none !important;
}

/* Collapse */

.ant-collapse-content-box {
  background-color: #f6f6f9 !important;
}

/* Ckeditor */
.customCkeditor .ck.ck-editor__editable_inline {
  border: none !important;
  background-color: #e9e8e8 !important;
}
.ck.ck-content ul,
.ck.ck-content ul {
  list-style: outside;
  padding: 15px;
}
.ck.ck-content ol {
  list-style: revert;
  padding: 15px;
}

.customScroll {
  ::-webkit-scrollbar-button:single-button:vertical:decrement {
    height: 12px;
    width: 16px;
    background-position: center 4px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='50,00 0,50 100,50'/></svg>");
  }

  ::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='50,00 0,50 100,50'/></svg>");
  }

  ::-webkit-scrollbar-button:single-button:vertical:decrement:active {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='50,00 0,50 100,50'/></svg>");
  }

  /* Down */
  ::-webkit-scrollbar-button:single-button:vertical:increment {
    height: 12px;
    width: 16px;
    background-position: center 2px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,0 100,0 50,50'/></svg>");
  }

  ::-webkit-scrollbar-button:single-button:vertical:increment:hover {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,0 100,0 50,50'/></svg>");
  }

  ::-webkit-scrollbar-button:single-button:vertical:increment:active {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,0 100,0 50,50'/></svg>");
  }

  /* Left */
  ::-webkit-scrollbar-button:single-button:horizontal:decrement {
    height: 12px;
    width: 12px;
    background-position: 3px 3px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,50 50,100 50,0'/></svg>");
  }

  ::-webkit-scrollbar-button:single-button:horizontal:decrement:hover {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,50 50,100 50,0'/></svg>");
  }

  ::-webkit-scrollbar-button:single-button:horizontal:decrement:active {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,50 50,100 50,0'/></svg>");
  }

  /* Right */
  ::-webkit-scrollbar-button:single-button:horizontal:increment {
    height: 12px;
    width: 12px;
    background-position: 3px 3px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,0 0,100 50,50'/></svg>");
  }

  ::-webkit-scrollbar-button:single-button:horizontal:increment:hover {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,0 0,100 50,50'/></svg>");
  }

  ::-webkit-scrollbar-button:single-button:horizontal:increment:active {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,0 0,100 50,50'/></svg>");
  }
  &::-webkit-scrollbar {
    width: 10px;
    border-top: 10px solid transparent !important ;
    border-right: 10px solid transparent !important;
  }

  &::-webkit-scrollbar-track {
    background: #a9a7a7;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #828282;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #888;
  }
}
